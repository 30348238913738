import React from 'react';
import PropTypes from 'prop-types';

// Import components
// import Icon from '../shared/Icon/Icon';

const Intro = ({ setIsFormStarted }) => {
  const handleClick = () => {
    setIsFormStarted(true);
  };

  return (
    <div className="ds-col-1 ds-col-md-2-3" data-testid="intro">
      <h1>Contact us</h1>
      <h2>Use this service to:</h2>
      <ul>
        <li>Make an enquiry or provide feedback</li>
      </ul>
      <p>This process takes around 5 minutes.</p>
      <button
        type="button"
        className="ds-btn ds-btn--start ds-m-b-md ds-col-1 ds-col-sm-auto"
        onClick={handleClick}
      >
        Start
        <svg className="ds-btn__icon ds-btn__icon--right">
          <use xlinkHref="#ds-general-chevron-right" href="#ds-general-chevron-right" />
        </svg>
      </button>
    </div>
  );
};
Intro.propTypes = {
  setIsFormStarted: PropTypes.func.isRequired,
};
export default Intro;
