import React, { useRef } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Import components
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
import Email from '../Shared/Email/NoFieldSet/Email';

const YourEmail = (props) => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)
  const { summary } = props;
  // Check it we are facing an existing user

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 1 of 2" description="About you" />

      {/* Show generic error message */}
      {showGenericError}

      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">What is your email?</h2>
          <p>We&apos;ll use this information to confirm receipt of your enquiry report</p>
          <p>{summary}</p>
        </legend>

        <Email />
      </fieldset>

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};
YourEmail.propTypes = {
  summary: PropTypes.string,
};

YourEmail.defaultProps = {
  summary: '',
};
export default YourEmail;
