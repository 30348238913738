import React, { useRef } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Import components
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
import PhoneNumber from '../Shared/PhoneNumber/NoFieldSet/PhoneNumber';

const PublicPhoneNumber = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)
  // Check it we are facing an existing user

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 1 of 2" description="About you" />

      {/* Show generic error message */}
      {showGenericError}

      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">What is your phone number?</h2>
          <p>We&apos;ll use this information to contact you if we require any more information</p>
        </legend>
        <PhoneNumber />
      </fieldset>

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default PublicPhoneNumber;
