/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Import components
import Input from 'components/shared/FormElements/Input/Input';

const Email = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { register } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  // Labels used on inputs and for validation
  const emailLabel = 'Email address';

  // Logic used to validate the email field
  const emailRegex =
    /^[\w!#$%&amp;'*+\-/=?^_`{|}~]+(\.[\w!#$%&amp;'*+\-/=?^_`{|}~]+)*@((([-\w]+\.)+[a-zA-Z]{2,4})|(([0-9]{1,3}\.){3}[0-9]{1,3}))$/; // Matches email regex on server

  // email validation
  const emailValidation = register({
    required: `${emailLabel} is required`,
    pattern: {
      value: emailRegex,
      message: `Enter an ${emailLabel.toLowerCase()} in the correct format`,
    },
  });

  return (
    <div>
      <Input
        className="ds-col-1 ds-col-lg-3-4"
        name="ContactEmail"
        label="Email address<br> For example, name@example.com"
        autocomplete="given-name"
        fieldValidation={emailValidation}
      />
    </div>
  );
};

export default Email;
