/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useContext } from 'react';
// Context
import { FormDataContext } from 'globalState/FormDataContext';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Import components
import Input from 'components/shared/FormElements/Input/textarea';
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';

const EnquiryDetails = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { register, showGenericError, continueButton, handleSubmit } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  const [formDataState] = useContext(FormDataContext);
  const { EnquiryType } = formDataState.formData;

  let enquiryDetailsLabel = 'Your enquiry';
  if (EnquiryType === 'feedback') {
    enquiryDetailsLabel = 'Your feedback';
  }

  let enquiryTypeQuestion = 'What is your enquiry?';
  if (EnquiryType === 'feedback') {
    enquiryTypeQuestion = 'What is your feedback?';
  }

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 2 of 2" description={enquiryDetailsLabel} />

      {/* Show generic error message */}
      {showGenericError}

      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">{enquiryTypeQuestion}</h2>
        </legend>

        <Input
          className="ds-col-1"
          name="EnquiryDetails"
          label={enquiryDetailsLabel}
          fieldValidation={register({
            required: `Enquiry details is required`,
          })}
          rows={5}
        />
      </fieldset>

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default EnquiryDetails;
