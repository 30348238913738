import React from 'react';

function Success() {
  // eslint-disable-next-line no-unused-vars

  const title = 'We’ve received your enquiry';

  return (
    <div className="ds-container-alerts-sign-up">
      <div className="ds-grid ds-col-1 ds-col-md-2-3">
        <div className="ds-col-1">
          <h1>{title}</h1>
        </div>

        <div className="ds-col-1">
          <h2>What happens next</h2>
          <p>You&apos;ll receive an email to confirm that we have received your incident report.</p>
          <p>
            It can take up to 5 working days to process a request. It may take longer if we need
            more information from you.
          </p>
          <p>
            If you need to contact us, email{' '}
            <a href="mailto:SaferTravelASBTeam@wmca.org.uk">SaferTravelASBTeam@wmca.org.uk</a>. You
            can also text us on 81018.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Success;
