import React, { useContext } from 'react';
// Hooks

// Context
import { FormDataContext } from 'globalState/FormDataContext';
// Components
import Table from 'components/shared/Table/Table';

import ConsentForm from './StepConsentForm';

function StepSummarySection() {
  const [formDataState, formDataDispatch] = useContext(FormDataContext);
  const { Firstname, LastName, EnquiryType, ContactEmail, PhoneNumber, EnquiryDetails } =
    formDataState.formData;

  const setStepInContext = (st) => {
    formDataDispatch({
      type: 'UPDATE_STEP',
      payload: st,
    });
  };

  const title = 'Check your answers';

  // Labels used on inputs and for validation
  let enquiryDetailsLabel = 'Your enquiry';
  if (EnquiryType === 'feedback') {
    enquiryDetailsLabel = 'Your feedback';
  }

  /* Table Data */
  // name
  const dataLine2 = [];
  dataLine2.push(<span>Name</span>);
  dataLine2.push(<span>{`${Firstname} ${LastName}`}</span>);
  dataLine2.push(
    <button
      type="button"
      className="ds-btn ds-btn--link"
      onClick={() => {
        setStepInContext(2);
      }}
    >
      Change
    </button>
  );

  // email
  const dataLine3 = [];
  dataLine3.push(<span>Email</span>);
  dataLine3.push(<span>{`${ContactEmail} `}</span>);
  dataLine3.push(
    <button
      type="button"
      className="ds-btn ds-btn--link"
      onClick={() => {
        setStepInContext(3);
      }}
    >
      Change
    </button>
  );

  // phone number
  const dataLine4 = [];
  dataLine4.push(<span>Phone number</span>);
  dataLine4.push(<span>{`${PhoneNumber} `}</span>);
  dataLine4.push(
    <button
      type="button"
      className="ds-btn ds-btn--link"
      onClick={() => {
        setStepInContext(4);
      }}
    >
      Change
    </button>
  );

  // date and time of the enquiry
  const dataLine5 = [];
  dataLine5.push(<span>{enquiryDetailsLabel}?</span>);
  dataLine5.push(<span>{`${EnquiryDetails}`}</span>);
  dataLine5.push(
    <button
      type="button"
      className="ds-btn ds-btn--link"
      onClick={() => {
        setStepInContext(5);
      }}
    >
      Change
    </button>
  );

  /* End of Table Data */

  const data = [dataLine2, dataLine3, dataLine4];
  const enquiry = [dataLine5];

  return (
    <>
      <div className="ds-col-1">
        <h2 className="ds-fe-question ds-m-t-none">{title}</h2>

        <Table
          title="About you"
          classes=""
          cellClasses={['', '', 'ds-text-align-right ds-p-r-none']}
          headers={[]}
          values={data}
          data-private
        />

        <Table
          title={enquiryDetailsLabel}
          classes=""
          cellClasses={['', '', 'ds-text-align-right ds-p-r-none']}
          headers={[]}
          values={enquiry}
          data-private
        />

        <h3>Now send your report</h3>
        <p>
          By submitting this report you are confirming that, to the best of your knowledge, the
          details you are providing are correct.
        </p>
        <ConsentForm />
      </div>
    </>
  );
}

export default StepSummarySection;
