/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from 'react';

import useStepLogic from 'components/Form/useStepLogic';
import Radios from 'components/shared/FormElements/Radios/Radios';
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';

const EnquirySelection = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { register, handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  const radioButtons = [
    { text: 'Make and enquiry', value: 'enquiry' },
    { text: 'Provide feedback', value: 'feedback' },
  ];

  const title = 'Why do you want to contact us?';

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 1 of 2" description="About your enquiry" />

      {showGenericError}
      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">{title}</h2>
        </legend>

        <Radios
          name="EnquiryType"
          radios={radioButtons}
          fieldValidation={register({
            required: `Please select one option to proceed`,
          })}
        />
      </fieldset>

      {continueButton()}
    </form>
  );
};

export default EnquirySelection;
