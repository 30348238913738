import React, { useContext, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useForm, FormProvider } from 'react-hook-form';

// Import contexts
import { FormDataContext } from 'globalState/FormDataContext';

// Import components
import Step1Enquiry from './Step1/EnquirySelection';
import PublicStep2Who from './Step2/Who';
import PublicStep3Email from './Step3/YourEmail';
import PublicStep4PhoneNumber from './Step4/PhoneNumber';
import PublicStep5EnquiryDetails from './Step5/EnquiryDetails';
import Confirmation from './StepConfirm/StepConfirm';

// Custom Hooks
import useTrackFormAbandonment from './useTrackFormAbandonment';

const Form = ({ formSubmitStatus, setFormSubmitStatus, isRecoverLinkPressed }) => {
  // Handle scrolling to the top of the summary page
  const formRef = useRef();
  const scrollToTopOfSummary = useCallback(() => {
    const page = document.getElementsByClassName('ds-html')[0];
    const pageOffset = page.scrollTop;
    const formOffset = formRef.current.offsetTop;
    if (formOffset >= pageOffset) return;
    page.scrollTo(0, formOffset - 20);
  }, []);

  const [formDataState, formDataDispatch] = useContext(FormDataContext); // Get the state/dispatch of form data from FormDataContext
  const { currentStep } = formDataState; // Destructure step from state
  const methods = useForm({
    mode: 'onBlur',
  }); // Trigger validation onBlur events (config for react hook form lib)

  useEffect(() => {
    if (currentStep === 9) scrollToTopOfSummary();
  }, [currentStep, scrollToTopOfSummary]);

  useEffect(() => {
    if (isRecoverLinkPressed) {
      formDataDispatch({
        type: 'UPDATE_STEP',
        payload: 0,
      });
    }
  }, [formDataDispatch, isRecoverLinkPressed]);

  useTrackFormAbandonment(currentStep, formSubmitStatus);

  // Show debug options for below (this should be deleted on release)
  const debugStepOptions = [0, 1, 2, 3, 4, 5, 6];

  let stepToGoTo;

  if (currentStep > 1 && currentStep < 6) {
    stepToGoTo = currentStep - 1;
  }

  // Run! Like go get some data from an API.
  return (
    <>
      {/* pass all methods into the context */}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <div className="ds-col-1 ds-col-md-2-3">
          {stepToGoTo && (
            <div className="ds-col-1 ds-m-b-md">
              <button
                type="button"
                className="ds-btn ds-btn--link"
                onClick={() =>
                  formDataDispatch({
                    type: 'UPDATE_STEP',
                    payload: stepToGoTo,
                  })
                }
              >
                &lt; Back
              </button>
            </div>
          )}

          <div className="bg-white ds-p-lg ds-p-l-md ds-col-1">
            {/* Start of form */}
            {currentStep === 1 && <Step1Enquiry setFormSubmitStatus={setFormSubmitStatus} />}
            {currentStep === 2 && <PublicStep2Who />}
            {currentStep === 3 && <PublicStep3Email />}
            {currentStep === 4 && <PublicStep4PhoneNumber />}
            {currentStep === 5 && <PublicStep5EnquiryDetails />}
            {currentStep === 6 && <Confirmation setFormSubmitStatus={setFormSubmitStatus} />}
          </div>
        </div>
        {/* If in development based on envs then show form debugging */}
        {process.env.NODE_ENV !== 'production' && (
          <div
            className="ds-col-1 ds-col-md-1-4 ds-p-md"
            style={{
              overflowX: 'auto',
              position: 'fixed',
              right: 0,
            }}
          >
            <pre>{JSON.stringify(formDataState, null, 2)}</pre>
            <br />
            <div className="ds-col-1">
              Select step: {}
              <select
                onChange={(e) =>
                  formDataDispatch({
                    type: 'UPDATE_STEP',
                    payload: +e.target.value,
                  })
                }
                onBlur={(e) =>
                  formDataDispatch({
                    type: 'UPDATE_STEP',
                    payload: +e.target.value,
                  })
                }
                value={currentStep}
              >
                {debugStepOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </FormProvider>
    </>
  );
};

Form.propTypes = {
  formSubmitStatus: PropTypes.bool,
  setFormSubmitStatus: PropTypes.func.isRequired,
  setIsFormStarted: PropTypes.func.isRequired,
  isRecoverLinkPressed: PropTypes.bool,
  setIsRecoverLinkPressed: PropTypes.func.isRequired,
};

Form.defaultProps = {
  formSubmitStatus: null,
  isRecoverLinkPressed: false,
};

export default Form;
